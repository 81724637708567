/* :root {
  --sat: 50px;
  --sar: env(safe-area-inset-right);
  --sab: 35px;
  --sal: env(safe-area-inset-left);
} */

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

#control-height {
  height: 100vh;
  width: 0;
  position: absolute;
}

.fullHeight {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overscroll-behavior-y: contain;

}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}


.ptr {
  height: unset !important;
  width: unset !important;
  /* overflow: hidden; */
}

.ptr .ptr__pull-down {
  text-align: center;
}

.ptr .ptr__pull-down {
  top: 15px;
}

.ptr .lds-ellipsis div {
  width: 20px;
}

.ptr .lds-ellipsis div {
  top: 5px;
  width: 7px;
  height: 7px;
}

/* :root {
  --safe-area-inset-top: 50px;
  --safe-area-inset-bottom: 35px;
} */

.safe-area-top {
  padding-top: constant(safe-area-inset-top) !important;
  /* for ios 13+ */
  padding-top: env(safe-area-inset-top) !important;
  /* for previous ios versions */
  /* padding-top: 50px!important; */
}

.safe-area-bottom {
  padding-bottom: constant(safe-area-inset-bottom) !important;
  /* for ios 13+ */
  padding-bottom: env(safe-area-inset-bottom) !important;
  /* for previous ios versions */
  /* padding-bottom: 35px!important; */
}














.xyz {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(180deg, rgba(2, 0, 0, 0) 0%, rgba(247, 247, 249, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(2, 0, 0, 0) 0%, rgba(247, 247, 249, 1) 100%);
  background: linear-gradient(180deg, rgba(2, 0, 0, 0) 0%, rgba(247, 247, 249, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024", endColorstr="#f7f7f9", GradientType=1);
}

.time-loss dt,
.time-loss dd {
  float: left;
  width: 50%; 
  padding: 0;
  margin: 0
}

.MuiBottomNavigationAction-root:not(.Mui-selected) {
  border-top-color: #FFF!important;
}


.attack-tabs .MuiTab-wrapper {
  flex-direction: row;
  align-items: flex-start;
}

.attack-tabs .MuiTab-labelIcon {
  min-height: 54px;
  padding-top: 14px;
}

.attack-tabs .MuiTabs-indicator {
  display: none;
}

.attack-tabs .MuiTab-wrapper {
  flex-direction: row;
  align-items: flex-start;
}

.attack-tabs .MuiTab-wrapper .MuiSvgIcon-root {
  padding-right: 5px;
}


.MuiPaper-root.MuiStepper-root {
  background-color: transparent;
}
